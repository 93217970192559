import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getPaketPekerjaan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getAllPaketPekerjaan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/all', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addPaketPekerjaan(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/paket_pekerjaan', data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updatePaketPekerjaan(_, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.put('/paket_pekerjaan/update/' + id, data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deletePaketPekerjaan(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/paket_pekerjaan/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/paket_pekerjaan/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentase(_, params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/persentase', { params: params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseOpd(_, params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/persentase-opd', { params: params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseKecamatan(_, params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/persentase-kecamatan', { params: params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentase_sumber_dana(_, params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/persentase_sumber_dana', { params: params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentase_sumber_dana_apbn(_, params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/persentase_sumber_dana_apbn', { params: params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseTahunan(_) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/persentase-tahunan')
                .then(resp => resolve(resp))
                .catch(e => reject(e));
            });
        },

        other(_, params) {
            return new Promise((resolve, reject) => {
                axios.get('/paket_pekerjaan/other', { params: params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        progress(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/paket_pekerjaan/progress', data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        import(_, file) {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('file', file);
                const headers = { 'Content-Type': 'multipart/form-data' };
                let config = {
                    headers
                }
                axios.post('/paket_pekerjaan/selected_action/import_excel', formData, config).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        },

        addPaketPekerjaanFromApi(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/paket_pekerjaan/create_from_api', data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}