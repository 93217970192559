import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        // getTenderSelesai(_, _params) {
        //     return new Promise((resolve, reject) => {
        //         axios({
        //                 method: 'GET',
        //                 url: 'isb-2/api/3edbadd7-85cd-45b7-a1a8-3ad1c0898438/json/5347/SPSE-TenderSelesai/tipe/4:4/parameter/2023:93',
        //                 baseURL: 'https://isb.lkpp.go.id/',
        //                 withCredentials: false,
        //                 headers: {
        //                     'Access-Control-Allow-Origin' : '*',
        //                     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        //                 }
        //             })
        //             .then(response => {
        //               resolve(response.data);
        //             })
        //             .catch(err => {
        //                 reject(err);
        //             });
        //     });
        // },

        // getTenderSelesai(_, _params) {
        //     return new Promise((resolve, reject) => {
        //         axios.get('/tender-selesai').then(resp => {
        //             resolve(resp.data);
        //         }).catch(e => {
        //             reject(e);
        //         })
        //     });
        // },

        getTenderSelesai(_, params) {
            return new Promise((resolve, reject) => {
                fetch("https://isb.lkpp.go.id/isb-2/api/3edbadd7-85cd-45b7-a1a8-3ad1c0898438/json/5347/SPSE-TenderSelesai/tipe/4:4/parameter/2023:93")
                .then(async response => {
                    const data = await response.json();
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }                    
                    console.log(data, "response!");
                    resolve(data);
                })
                .catch(error => {
                    this.errorMessage = error;
                    reject(error);
                    console.error("There was an error!", error);
                });
            });
        }
    },
    getters: {
    }
}