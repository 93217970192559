import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getMasterRup(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/master-rup', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getDataMasterRup(_, params) {
            return new Promise((resolve, reject) => {
                fetch("https://isb.lkpp.go.id/isb-2/api/7201cbd3-6a84-44dc-ada7-96c8bdaad80e/json/5344/RUP-KegiatanMaster/tipe/4:12/parameter/2024:D107")
                .then(async response => {
                    const data = await response.json();
                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }                    
                    console.log(data, "response!");
                    resolve(data);
                })
                .catch(error => {
                    this.errorMessage = error;
                    reject(error);
                    console.error("There was an error!", error);
                });
            });
        }
    },
    getters: {
    }
}